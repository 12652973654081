const orgNameNoStyleHandler = (orgName) => {
    var orgNameArray = orgName.split(" ");
    var name = orgName;
    for (let i = 0; i < orgNameArray.length; i++) {
        var partialName = orgNameArray[i];
        partialName = partialName.toLowerCase();
        orgNameArray[i] = partialName;
    }
    name = orgNameArray.join("_");
    name = name.replace(/[^a-zA-Z0-9]/g, '_');
    
    return name;
}

export default orgNameNoStyleHandler;