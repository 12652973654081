import {KEYUTIL, KJUR, b64tohex, hextob64 } from "jsrsasign";

const viewerSign = (privateKeyString, nonce) => {
    return new Promise((resolve, reject) => {
        try{
            // convert DER to PEM
            const derPrivateKeyHex = b64tohex(privateKeyString);
            const pemEcPrivateKeyString = KJUR.asn1.ASN1Util.getPEMStringFromHex(derPrivateKeyHex, "PRIVATE KEY");
            const pemEcPrivateKey = KEYUTIL.getKey(pemEcPrivateKeyString);
            const signature = new KJUR.crypto.Signature({"alg": "SHA256withECDSA"});
            
            signature.init(pemEcPrivateKey);
            signature.updateString(nonce);
            const signatureHex = signature.sign();
            const signatureString = hextob64(signatureHex);
            resolve(signatureString); 
        } catch (error) {
            reject(error);
        }
    })
}

export {viewerSign};