import {accessInstance} from "../apiHandler/handler";
import axios from "axios"

const BASIC_URL=process.env.REACT_APP_N7_API_URL;



const cognitoSignUp = (user) => {
    const url = BASIC_URL + "/auth/register";
    return accessInstance.post(url, user);
}

const disconnect = () => {
    const url = BASIC_URL + "/auth/disconnect"
    return accessInstance.post(url)
}

const cognitoLogin = (user) => {
    const url = BASIC_URL + "/auth/login";
    return accessInstance.post(url, user);
}

const getViewerByHash = (viewerHash) => {
    const url = BASIC_URL + "/" + viewerHash;
    return accessInstance.get(url);
}

const findViewer = (viewerName, token) => {
    const encodedName = encodeURIComponent(viewerName);
    const url = BASIC_URL + "/name/" + encodedName;
    return axios.get(url, {headers: {
        "Authorization" : `Bearer ${token}`
    }});
}

const addViewer = (newViewer, token) => {
    const url = BASIC_URL + "/viewer";
    return axios.post(url, newViewer, {headers: {
        "Authorization" : `Bearer ${token}`
    }});
}



export {cognitoSignUp, cognitoLogin, disconnect, getViewerByHash, findViewer, addViewer};
