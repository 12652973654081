import axios from "axios";
const { wrapper } = require("axios-cookiejar-support")
const { CookieJar } = require('tough-cookie')

wrapper(axios)

const idInstance = axios.create({
  withCredentials: true,
  jar: CookieJar
});
const accessInstance = axios.create({
  withCredentials: true,
  jar: CookieJar
});

accessInstance.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    return Promise.reject(error);
});

accessInstance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401) {
      window.location.assign("/")
    }
    return Promise.reject(error);
  });

idInstance.interceptors.request.use(function (config) {
    return config;
  }, function (error) {
    return Promise.reject(error);
});

export {idInstance, accessInstance};