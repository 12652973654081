import orgNameNoStyleHandler from "../components/OrgNameNoStyleManip"

const getChannel = (cp) => {
    switch (cp) {
        case "OM":
            return "n7ch1"
            
        case "Real de Madrid":
            return "n7ch1"

        case "PSG":
            return "n7ch2"

        case "FC Barcelone":
            return "n7ch2"

        case "Sud Ouest":
            return "n7ch2"

        case "Ma TV d'Orange":
            return "n7ch1"
        
        default:
            break;
    }
}

const getContentCode = (contentId, cp) => {
    const cpArray = cp.split(" ");
    const cpString = cpArray.join("-");
    const contentCode = contentId + "-" + orgNameNoStyleHandler(cpString);
    return contentCode;
}

export {getChannel, getContentCode};