import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
.use(initReactI18next)
.use(LanguageDetector)
.init(
    {
        debug: true,
        fallbackLng: 'fr',
        resources: {
            fr: {
                translation: {
                    //login
                    login: "S'identifier",
                    user_name: "Identifiant",
                    your_user_name: "Votre identifiant",
                    pwd: "Mot de passe",
                    your_pwd: "Votre mot de passe",
                    your_email: "Votre email",
                    sign_in: "S'identifier",
                    sign_up: "Créer un compte",
                    send_verification_code: "Envoyer le code de vérification",
                    enter_user_name_msg: "Veilliez saisir votre identifiant!",
                    enter_user_pwd_msg: "Veilliez saisir votre mot de passe!",
                    enter_user_email: "Veilliez saisir votre email!",
                    wrong_user_name_or_wrong_pwd: "Mauvais identifiant ou mauvais mot de passe",
                    wrong_pwd: "Mauvais Mot de Passe",
                    verification_code: "Code de vérification",
                    //header
                    welcome_msg: "Bonjour, ",
                    end_date: "Date de fin de livraison",
                    purchase_msg: "Vous vous apprétez à acheter un accès au contenu vidéo",
                    payment: "Paiement",
                    //foot
                    about_n7_project: "À PROPOS DU PROJET N7",
                    contact_n7: "CONTACTER N7",
                    made_by_n7_development_team: "réalisé par l'équipe de développement de N7",
                    //errors
                    error_404: "Erreur 404",
                    page_not_found: "Page non Trouvée",
                    retry: "Nous vous invitons à ressayer votre action, en cas de nouvelle erreur, nous vous invitons à ressayer plus tard.",
                    bc_error: "Une erreur est survenue sur la blockchain.",
                    //users
                    content_owner: "Content Owner",
                    content_provider: "Content Provider",
                    service_provider : "Service Provider",
                    viewer: "Consommateur",
                    viewers: "Consommateurs",
                    viewer_s: "Consommateur(s)",
                    //content
                    all: "Tous",
                    content: "Contenu",
                    contents: "Contenus",
                    content_s: "Contenu(s)",
                    //content info
                    content_info: "Informations sur Contenus",
                    content_nb: "Nombre de Contenus",
                    content_type: "Type du Contenu",
                    content_name: "Nom du Contenu",
                    price: "Prix",
                    //viewer
                    email: "E-mail",
                    credit_card_name: "Nom sur la carte",
                    name: "Nom",
                    credit_card_number: "N° de carte",
                    expiry_date: "Date d'expiration",
                    cvc: "Cryptogramme visuel",
                    cvc_info: "Au verso de la carte, à droite de la zone de signature. (3 chiffres)",
                    //action
                    close: "Fermer",
                    action: "Action",
                    validate: "Valider",
                    cancel: "Annuler",
                    return: "Retour",
                    watch : "Regarder",
                        //content
                    preview_content: "Prévisualiser",
                    buy_content_access: "S’offrir l’accès",
                    buy: "Achat",
                    pay: "Payer",
                    //action feedback
                    field_not_null: "Ce champ est obligatoire!",
                    correct_email: "Veuillez entrer une adresse valide!",
                    correct_card: "Veuillez entrer un numéro de carte bancaire valide!",
                    correct_date: "Votre carte n'est plus valable!",
                    correct_cvc: "Veuillez entrer un CVC valide!",
                    numbers_required: "Ce champ ne contient que des chiffres!",
                    buy_failed: "Échec de l'achat!",
                    buy_valid: "Merci pour votre achat!",
                    loading:"Chargement...",
                    loading_msg: "Chargement en cours",
                    please_wait: "Veuillez patienter quelques instants",
                    no_content: "Pas de contenus disponibles",
                    no_content_with_filter: "Il n’existe pas de données qui correspondent au filtre sélectionné",
                    no_content_without_filter: "Il n'y a pas de données à afficher."
                }
            },
            en: {
                translation: {
                    //login
                    login: "Login",
                    user_name: "User Name",
                    your_user_name: "Your user name",
                    pwd: "Password",
                    your_pwd: "Your password",
                    your_email: "Your email",
                    sign_in: "Sign In",
                    sign_up: "Sign Up",
                    send_verification_code: "Send verification code",
                    enter_user_name_msg: "Please, enter your user name!",
                    enter_user_pwd_msg: "Please, enter your password!",
                    enter_user_email: "Please, enter your email",
                    wrong_user_name_or_wrong_pwd: "Wrong user name or wrong password",
                    wrong_pwd: "Wrong Password",
                    verification_code: "Verification code",
                    //header
                    welcome_msg: "Hello, ",
                    end_date: "Availability End Date",
                    purchase_msg: "You agree to purchase to watch the video offered by ",
                    payment: "Payment",
                    //foot
                    about_n7_project: "ABOUT N7 PROJECT",
                    contact_n7: "CONTACT N7",
                    made_by_n7_development_team: "made by N7 development team",
                    //errors
                    error_404: "404 Error",
                    page_not_found: "Page not found",
                    retry: "We invite you to try your action again, if the error persists, we invite you to try again later.",
                    bc_error: "An error has occurred on the blockchain network",
                    //users
                    content_owner: "Content Owner",
                    content_provider: "Content Provider",
                    service_provider: "Service Provider",
                    viewer: "Viewer",
                    viewers: "Viewers",
                    viewer_s: "Viewer(s)",
                    //content
                    all: "All",
                    content: "Content",
                    contents: "Contents",
                    content_s: "Content(s)",
                    //content info
                    content_info: "Content Information",
                    content_nb: "Number of Contents",
                    content_type: "Content Type",
                    content_name: "Content Name",
                    price: "Price",
                    //viewer
                    email: "Email",
                    credit_card_name: "Name on the credit card",
                    credit_card_number: "Credit Card Number",
                    name: "Name",
                    expiry_date: "Expiry Date",
                    cvc: "CVC",
                    cvc_info: "On the back of the card, to the right of the signature area. (3 digits)",
                    //action
                    close: "Close",
                    action: "Action",
                    validate: "Validate",
                    cancel: "Cancel",
                    return: "Return",
                    watch: "Watch",
                        //content
                    preview_content: "Preview Content",
                    buy_content_access: "Buy Access",
                    buy: "Buy",
                    pay: "Pay",
                    //action feedback
                    field_not_null: "This field is required!",
                    correct_email: "Please fill in a correct email address!",
                    correct_card: "Please fill in a valid credit card number!",
                    correct_date: "Your card is no longer valid!",
                    correct_cvc: "Please fill in a valid CVC!",
                    numbers_required: "This field contains only numbers!",
                    buy_failed: "Failed purchase!",
                    buy_valid: "Successful purchase!",
                    loading: "Loading...",
                    loading_msg: "Loading in progress",
                    please_wait: "Please wait a few moments",
                    no_content: "No content available",
                    no_content_with_filter: "There are no data that correspond to the selected filter.",
                    no_content_without_filter: "There are no data to display."
                }
            }
        }
    }
)

export default i18next;