import React from "react";
import "./NoContent.css";

const NoContent = ({translator}) => {

    const noContentHandler = () => {
        const cpFilterNotNull = sessionStorage.getItem("cpFilter")!==null;
       
        if(cpFilterNotNull){
            return translator("no_content_with_filter")
        } else {
            return translator("no_content_without_filter")
        }
    }

    return (
        <div className="no-content-container">
            <img style={{width: "150px", height: "150px", filter: "invert(1)"}} alt="icon-searching" src={require("../components/icons/searching.png")} />
            <div className="no-content-info">
                <div className="no-content-info-title">
                    {translator("no_content")}
                </div>
                <div className="no-content-info-text-container">
                    <div className="no-content-info-text-subtitle"></div>
                    <div className="no-content-info-text">{noContentHandler()}</div>
                </div>
            </div>
        </div>
    )
}

export default NoContent;