import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss?v=1.3.0';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Route, Routes } from 'react-router-dom';
import SignIn from './userLogin/SignIn';
import Contents from './contents/Contents';
import Content from './contents/Content';
import BuyContent from './contents/BuyContent';
import { useTranslation } from 'react-i18next';
import Error1 from './errorManip/Error1';
import Error3 from './errorManip/Error3';
import useToken from './contents/useToken';

function App() {

    const { setToken } = useToken();
    const { t } = useTranslation();

    if(window.location.pathname.split("/")[1]===""){
        return(
			<div className="App">
    	    	<Routes>
					<Route path="*" element={<SignIn translator={ t } />} />
    	    	</Routes>
    		</div>
		) 
    } 

    const url = window.location.pathname;
    const urlArray = url.split("/");
    const viewerHash = urlArray[1];
    return (
        <div className="App">
            <Routes>
                <Route path="/:viewerHashId" element={<Contents translator={t} viewerHash={viewerHash} />} />
                <Route path="/:viewerHashId/contents" element={<Contents translator={t} viewerHash={viewerHash} />} />
                <Route path='/:viewerHashId/:contentStateId' element={<Content translator={t} viewerHash={viewerHash} />} />
                <Route path='/:viewerHashId/:contentStateId/purchase' element={<BuyContent translator={t} setToken={setToken} viewerHash={viewerHash} />} />
                <Route path="*" element={<Error1 translator={t} />} />
				<Route path="/:viewerHashId/bcError" element={<Error3 translator={t}/>} />
            </Routes>
        </div>
    );
}

export default App;
