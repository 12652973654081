import React, { useState, useEffect } from 'react';
import {getContentByContentId, getViewership, createPurchaseState, addViews} from './ContentManip';
import { getViewerByHash } from '../userLogin/UserManip';
import Title from "../components/Title"
import { Link } from 'react-router-dom';
import { Form, Input, DatePicker } from 'antd';
import {hoverDeleteHandler, unhoverDeleteHandler} from '../components/ButtonManip';
import LoadingButton from '../components/LoadingButton';
import Error1 from '../errorManip/Error1';
import Error3 from '../errorManip/Error3';
import { calculateContainerHeight } from '../components/ContainerDimensionManip';
import {getChannel, getContentCode} from '../blockchainManip/GetCode';
import { viewerSubscription, queryContent, viewerTokenQuery} from '../blockchainManip/BCManip';
import Navbar from '../components/Navbar';
import Foot from "../components/Foot"
import { decodeContentName } from '../components/ContentNameStyleManip';

const BuyContent = ({translator, setToken, viewerHash}) => {
    const [contentStateId, setContentStateId] = useState("");
    const [viewer, setViewer] = useState(null);
    const [content, setContent] = useState(null);
    const [cardName, setCardName] = useState(null);
    const [cardNumber, setCardNumber] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [cvc, setCVC] = useState(null);
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [formHeight, setFormHeight] = useState("");
    const [viewerNotExistError, setViewerNotExistError] = useState(false);
    
    const cardNameHandler = (cardName) => {
        setCardName(cardName.target.value);
    }

    const cardNumberHandler = (cardNumber) => {
        setCardNumber(cardNumber.target.value);
    }

    const expiryDateHandler = (expiryDate) => {
        setExpiryDate(expiryDate.$d);
    }

    const cvcHandler = (cvc) => {
        setCVC(cvc.target.value);
    }

    const valideCardNumber = (cardNumberInput) => {
        var withoutSpace = cardNumberInput.replace(/\s/g, '');
        const numberLength = withoutSpace.toString().length;
        const format = /^[0-9]*$/;
        return numberLength === 16 && format.test(withoutSpace);
    }

    const changeCardNumberFormat = (event) => {
        const key = event.key;
        const numbres = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

        if(numbres.includes(key)){
            var withoutSpace = event.target.value.replace(/\s/g, '');
            event.target.value = event.target.value.length===0 ? "" : withoutSpace.match(/.{1,4}/g).join(" ");
        }
    }

    const validExpiryDate = (expiryDateInput) => {
        const today = new Date();
        const expiryDateDate = new Date(expiryDateInput);
        var validity = false;
        if(expiryDateDate.getFullYear() > today.getFullYear()){
            validity = true
        } else if(expiryDateDate.getFullYear() === today.getFullYear()){
            if(expiryDateDate.getMonth() >= today.getMonth()){
                validity = true
            }
        }
        return validity;
    }

    const validCVC = (cvcInput) => {
        const cvcLength = cvcInput.toString().length;
        const format = /^[0-9]*$/;
        return cvcLength === 3 && format.test(cvcInput);
    }

    const validity = () => {
        const dataNotNull = cardName!==null && cardNumber!==null && expiryDate!==null && cvc!==null;
        if(dataNotNull){
            const cardNumberValidity = valideCardNumber(cardNumber);
            const dateValidity = validExpiryDate(expiryDate);
            const cvcValidity = validCVC(cvc);
            if(cardNumberValidity && dateValidity && cvcValidity){
                return <LoadingButton text={translator("pay") + " " + content.content_price + "€" } className="n7-button normal-button" onSubmit={buyContent} loading={showLoader} disabled={showLoader} />
            } else {
                return <button disabled className="n7-button normal-button disabled-button" style={{cursor: "default"}}>{translator("pay") + " " + content.content_price + "€"} </button>
            }
        } else {
            return <button disabled className="n7-button normal-button disabled-button" style={{cursor: "default"}}>{translator("pay") + " " + content.content_price + "€"} </button>
        }
    }

    const buyContent = async () => {
        setShowLoader(true);
    
        const contentId = content.content_unique_id;
        const cpName = content.content_provider_name;
        const channelName = getChannel(cpName);
        const viewership = await getViewership(contentStateId, viewerHash).then(res => res.data);
        
        // viewer 1st purchase
        if(viewership.length===0){
            viewerSubscription(channelName, getContentCode(contentId, cpName), viewerHash).then(() => {
                setTimeout(() => {
                    // check subscription
                    queryContent(channelName, getContentCode(contentId, cpName)).then( async (res) => {
                        const bcContent = JSON.parse(res.data.message);
                        const subscribedViewers = bcContent.viewerSubscription;
                        var foundViewer = false;
                        for (let i = 0; i < subscribedViewers.length; i++) {
                            const subscribedViewer = subscribedViewers[i];
                            if(subscribedViewer.viewerId===viewerHash){
                                foundViewer=true;
                                break;
                            }
                        }
                        
                        if(foundViewer===true){
                            createPurchaseState(contentStateId, viewer.id);
                            viewerTokenQuery(channelName, getContentCode(contentId, cpName), viewerHash).then(res => {
                                let token = {
                                    content: contentId,
                                    cp: content.content_provider_name,
                                    sp: content.service_provider_name,
                                    token: res.data.message,
                                    verification: null
                                }
                                setToken(token, contentStateId);
                                window.location.assign("/" + viewerHash + "/" + contentStateId);
                            }).catch((error) => {
                                setBcErrorOccured(true);
                                setBcErr({name: error.name, msg: error.message})
                            })
                        }
                    }).catch((error) => {
                        setBcErrorOccured(true);
                        setBcErr({name: error.name, msg: error.message})
                    });
                }, 3000); 
            }).catch((error) => {
                setBcErrorOccured(true);
                setBcErr({name: error.name, msg: error.message})
            })
        } else {
            var currentNbOfViews = viewership[0].views;
            var newNbOfViews = currentNbOfViews + 1;
            addViews(contentStateId, viewer.id, newNbOfViews);
            viewerTokenQuery(channelName, getContentCode(contentId, cpName), viewerHash).then(res => {
                let token = {
                    content: contentId,
                    cp: content.content_provider_name,
                    sp: content.service_provider_name,
                    token: res.data.message,
                    verification: null
                }
                setToken(token, contentStateId);
                window.location.assign("/" + viewerHash + "/" + contentStateId);
            }).catch((error) => {
                setBcErrorOccured(true);
                setBcErr({name: error.name, msg: error.message})
            })
        }
    }

    useEffect(() => {
        const init = async () => {
            const viewerData = await getViewerByHash(viewerHash).then(res => res.data);
            if(viewerData!==undefined && viewerData.length!==0){
                setViewer(viewerData[0]);
            } else {
                setViewerNotExistError(true);
            }

            const uri = window.location.href;
            const uriArray = uri.split("/");
            const contentStateIdValue = uriArray[uriArray.length - 2];
            setContentStateId(contentStateIdValue);
    
            const contentData = await getContentByContentId(contentStateIdValue).then(res => res.data);
            setContent(contentData.length===0 ? contentData : contentData[0]);
           
            const formHeightValue = calculateContainerHeight();
            if(formHeightValue !== formHeight){
                setFormHeight(formHeightValue);
            }
        };

        init();
    }, [formHeight, viewerHash])

    const calculateAvailabilityEndTime = (availabilityStartTime, accessDuration) => {
        var durationArray = accessDuration.split(" ");
        var nbYears = durationArray[0];
        var nbMonths = durationArray[2];
        var nbDays = durationArray[4];
        var nbHours = durationArray[6];
        var endTime = new Date(availabilityStartTime);
        endTime.setFullYear(endTime.getFullYear() + Number(nbYears));
        endTime.setMonth(endTime.getMonth() + Number(nbMonths));
        endTime.setDate(endTime.getDate() + Number(nbDays));
        endTime.setHours(endTime.getHours() + Number(nbHours));
        return endTime;
    }

    const validDate = (availabilityStartTime, accessDuration) => {
        const startTime = new Date(availabilityStartTime);
        const endTime = calculateAvailabilityEndTime(availabilityStartTime, accessDuration);
        const today = new Date();
        startTime.setMinutes(0, 0, 0);
        endTime.setMinutes(0, 0, 0);
        today.setMinutes(0,0,0);
        var validateStatus = false;
        if(today <= endTime) {
            validateStatus = true;
        }
        return validateStatus;
    }

    if(content !== null && viewer!==null){
        const todayValidity = validDate(content.availability_start_time, content.access_duration);
        if(content.length===0 || viewer.length===0 || !todayValidity || viewerNotExistError){
            return(
                <Error1 translator={translator} />
            )
        } else if(bcErrorOccured){
            return(
                <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
            )
        } else {
            return(
                <div className='wrapper'>
                    <Navbar translator={translator} />
                    <div className="main-panel-with-footer">
                        <div className="purchase-page-container" style={{maxHeight: formHeight}}>
                            <Title 
                                translator={translator} 
                                title={translator("payment")  + "."}
                            />
                            <Form
                                className="purchase-form-container"
                                name='buy-content'
                                layout='vertical'
                            >
                                <Form.Item
                                    name='contentName'
                                    label={translator("content_name")} 
                                >
                                    <Input placeholder={decodeContentName(content.content_name)} disabled />
                                </Form.Item>
                                <Form.Item
                                    name='cardName'
                                    label={translator("credit_card_name")} 
                                    rules={[
                                        {
                                            required: true,
                                            message: translator("field_not_null")
                                        }
                                    ]}
                                >
                                    <Input placeholder={translator("name")} autoComplete='off' onChange={cardNameHandler} />
                                </Form.Item>
                                <Form.Item
                                    name='cardNumber'
                                    label={translator("credit_card_number")} 
                                    rules={[
                                        {
                                            required: true,
                                            message: translator("field_not_null")
                                        },
                                        {
                                            validator: async (_, cardNumber) => {
                                                if(cardNumber!==null && cardNumber!=="" && cardNumber!==undefined){
                                                    if(!valideCardNumber(cardNumber)){
                                                        return Promise.reject(new Error(translator("correct_card")))
                                                    }
                                                }

                                            }
                                        }
                                    ]}
                                >
                                    <Input placeholder={"**** **** **** ****"} onKeyUp={changeCardNumberFormat} onChange={cardNumberHandler} autoComplete='off' />
                                </Form.Item>
                                <Form.Item
                                    name="expiryDate"
                                    label={translator("expiry_date")}
                                    rules={[
                                        {
                                            required: true,
                                            message: translator("field_not_null"),
                                        },
                                        {
                                            validator: async (_, expiryDate)=> {
                                                if(expiryDate!==null && expiryDate!==undefined){
                                                    if(!validExpiryDate(expiryDate)){
                                                        return Promise.reject(new Error(translator("correct_date")))
                                                    }
                                                }       
                                            }
                                        }
                                    ]}
                                >
                                    <DatePicker 
                                        autoComplete="off"
                                        picker="month"
                                        placeholder="MM/AAAA"
                                        format={"MM/YYYY"}
                                        onChange={expiryDateHandler}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="cvc"
                                    label={translator("cvc")}
                                    rules={[
                                        {
                                            required: true,
                                            message: translator("field_not_null")
                                        },
                                        {
                                            validator: async (_, cvc) => {
                                                if(cvc!==null && cvc !=="" && cvc!==undefined){
                                                    if(!validCVC(cvc)){
                                                        return Promise.reject(new Error(translator("correct_cvc")))
                                                    }
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <Input onChange={cvcHandler} placeholder="***" autoComplete='off' />
                                </Form.Item>
                            </Form>
                            <div style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'inline-flex'}}>
                                {validity()}
                                <Link to={"/" + viewerHash + "/contents"}>
                                    <input type='button' className='n7-button delete-button' onMouseEnter={hoverDeleteHandler} onMouseLeave={unhoverDeleteHandler} value={translator("cancel")} />
                                </Link>
                            </div>
                        </div>
                        <Foot translator={translator} />
                    </div>
                </div>
            )
        }
    }
}

export default BuyContent;