import {idInstance} from "../apiHandler/handler";

const BASIC_URL=process.env.REACT_APP_N7_API_URL + "/bc";

const queryContent = (channelName, contentId) => {
    const endpoint = "/" + channelName + "/supplied/contents/" + contentId;
    const url = BASIC_URL + endpoint;
    return idInstance.get(url);
}

const viewerSubscription = (channelName, suppliedContentId, viewerId) => {
    const endpoint = "/" + channelName + "/supplied/viewer";
    const url = BASIC_URL + endpoint;
    var viewer = {
        suppliedContentId: suppliedContentId,
        viewerId: viewerId
    }
    return idInstance.post(url, viewer)
}

const viewerTokenQuery = (channelName, suppliedContentId, viewerId) => {
    const endpoint="/" + channelName + "/supplied/" + suppliedContentId + "/" + viewerId + "/token";
    const url = BASIC_URL + endpoint;
    return idInstance.get(url)
}

const viewerNonceQuery = (channelName, contentId) => {
    const endpoint = "/" + channelName + "/supplied/" + contentId + "/nonce";
    const url = BASIC_URL + endpoint;
    return idInstance.get(url)
}

const viewershipVerification = (channelName, viewer) => {
    const validationEndpoint = "/" + channelName + "/supplied/viewership";
    const validationUrl = BASIC_URL + validationEndpoint;
    return idInstance.post(validationUrl, viewer)
}

const addProof = (channelName, proof) => {
    const proofUploadEndpoint = "/" + channelName + "/supplied/proof/new";
    const proofUploadUrl = BASIC_URL + proofUploadEndpoint;
    return idInstance.post(proofUploadUrl, proof)
}

export {queryContent, viewerSubscription, viewerTokenQuery, viewerNonceQuery, viewershipVerification, addProof}
