import {accessInstance} from "../apiHandler/handler";

const BASIC_URL = process.env.REACT_APP_N7_API_URL;

const getAllContents = () => {
    const url = BASIC_URL + "/contents";
    return accessInstance.get(url);
}

const getCps = () => {
    const url = BASIC_URL + "/cps";
    return accessInstance.get(url);
}

const getContentByContentId = (contentStateId) => {
    const url = BASIC_URL + "/contentState/" + contentStateId;
    return accessInstance.get(url);
}

const getViewership = (contentStateId, viewerHash) => {
    const url = BASIC_URL + "/content/" + contentStateId + "/viewership/" + viewerHash;
    return accessInstance.get(url);
}

const createPurchaseState = (contentStateId, viewer) => {
    let info = {
        viewerId: viewer,
        contentStateId: contentStateId,
        views: 1
    }
    const url= BASIC_URL + "/viewership";
    return accessInstance.post(url, info);
}

const addViews = (contentStateId, viewerId, views) => {
    let info = {
        viewerId: viewerId,
        contentStateId: contentStateId,
        views: views
    }
    const url = BASIC_URL + "/views";
    return accessInstance.put(url, info);
}

const newStream = (stream) => {
    const streamManipBasicURL = process.env.REACT_APP_N7_STREAM_API_URL;
    return accessInstance.post(streamManipBasicURL, stream);
}

export {getAllContents, getCps, getContentByContentId, getViewership, createPurchaseState, addViews, newStream};
