import React, { useState } from "react";
import CryptoJs from "crypto-js"; 
import { Form, Input } from "antd";
import "../components/UserLogin.css";
import LoadingButton from "../components/LoadingButton";
import {hoverSubmitHandler, unhoverSubmitHandler} from "../components/ButtonManip";
import {cognitoLogin } from "./UserManip";
import LoginNavbar from "../components/LoginNavbar";


const SignIn = ({translator}) => {
    
    const [viewerName, setViewerName] = useState("");
    const [viewerPwd, setViewerPwd] = useState("");
    const [showLoader, setShowLoader] = useState(false);

    const getViewerHashId = (viewerId) => {
        var hash = CryptoJs.SHA256(viewerId.toString());
        var hashHex = hash.toString(CryptoJs.enc.Hex);
        var hashId = hashHex.substring(0, 8);
        return hashId;
    }

    const onFinish = async() => {
        setShowLoader(true);
        try{
            let viewer = {
                username: viewerName, 
                password: viewerPwd
            }
            cognitoLogin(viewer).then((res) => {
                const data = res.data
                console.log(data)
                const viewerId = data.id
                const hashCpId = getViewerHashId(viewerId)
                const uri = "/" + hashCpId + "/contents"
                window.location.assign(uri)
            }).catch(() => {
                alert(translator("wrong_user_name_or_wrong_pwd"))
                setShowLoader(false);
            })
        } catch(error){
            alert(error);
        }
    }

    const viewerNameHandler = (event) => {
        setViewerName(event.target.value);
    }
    
    const viewerPwdHandler = (event) => {
        setViewerPwd(event.target.value);
    }

    const formVerification = () => {
        return (
            <div className="login-submit-btn">
                <LoadingButton text={translator('sign_in')} className="n7-button normal-button" onMouseEnter={(e)=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} onSubmit={onFinish} loading={showLoader} disabled={showLoader} />
            </div>
        )
    }

    return (
        <div className="wrapper">
            <div className="navbar">
                <LoginNavbar />
            </div>
            <div className="login-container">
                <div className="login-form-container">
                    <div className="login-input-container">
                        <Form 
                            name="normal_login"
                            layout="vertical"
                            className="login-inputs"
                        >
                            <Form.Item
                                name="content_provider_user_name"
                                label={translator("user_name")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_name_msg')
                                }]}>
                                <Input 
                                    placeholder={translator('your_user_name')} 
                                    autoComplete="off"
                                    value={viewerName}
                                    onChange={viewerNameHandler}/>
                            </Form.Item>
                            <Form.Item
                                name="content_provider_user_pwd"
                                label={translator("pwd")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_pwd_msg')
                                }]}>
                                <Input.Password
                                    type="password"
                                    placeholder={translator('your_pwd')}
                                    autoComplete="off"
                                    value={viewerPwd}
                                    onChange={viewerPwdHandler}/>
                            </Form.Item>  
                        </Form>
                    </div>
                    <div className="login-submit-btn">
                        {formVerification()}
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer-left-panel">
                    <div className="footer-left-panel-about-n7">
                        <a id="about-n7-link" href={window.location.href}>
                            {translator('about_n7_project')}
                        </a>
                    </div>
                    <div className="footer-left-panel-contact">
                        <a id="contact-link" href={window.location.href}>
                            {translator('contact_n7')}
                        </a>
                    </div>
                </div>
                <div className="footer-right-panel">&copy; {1900 + new Date().getYear()}, v1, {translator('made_by_n7_development_team')}</div>
            </div>
        </div>
    )
}

export default SignIn;
